import { addons } from "@storybook/manager-api";
import EverchainTheme from "./EverchainTheme";

const favicon = require("../public/img/favicon.svg") as string;

const link = document.createElement("link");
link.setAttribute("rel", "shortcut icon");
link.setAttribute("href", favicon);
document.head.appendChild(link);

addons.setConfig({
  theme: EverchainTheme,
});
