import { create } from "@storybook/theming/create";

const logo = require("../public/img/ec-color-logo.svg") as string;

export default create({
  base: "light",
  brandTitle: "Everchain Storybook",
  brandUrl: "https://www.everchain.com/",
  brandImage: logo,
  brandTarget: "_self",
});
